import { goNextRoute } from '@/config/router';

export default async function authorize({ to, from, store }) {
  let nextRoute = null;

  if (!store.getters['Account/isAuthorized']) {
    nextRoute = {
      name: 'Login',
      query: {
        destination: to.fullPath,
        ...(to.query || {}),
      },
    };
  }

  return goNextRoute(from, nextRoute);
}
