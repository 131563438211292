import IndexedDBStorage from '@/core/storage/drivers/indexedDBStorage';
import {
  validate,
} from '@/api/repositories/authRepository';
import {
  getRequiredRoute,
  goNextRoute,
} from '@/config/router';

const idbDict = IndexedDBStorage('DictionaryStorage');

export default async function validateComponent({ to, from, store }) {
  const nextPath = encodeURI(to.fullPath);
  const requiredRoute = getRequiredRoute(to.name);
  const { component } = to.meta;

  // check authorized
  if (!store.getters['Account/isAuthorized']) {
    return goNextRoute(from, {
      name: 'Login',
      query: { destination: nextPath },
    });
  } else if (requiredRoute) { // check required route by user status
    return goNextRoute(from, requiredRoute);
  }

  // check permission to render component
  if (!store.getters['Account/hasComponent'](component)) {
    return goNextRoute(from, {
      name: 'Forbidden',
    });
  }

  let validateResponse;

  try {
    validateResponse = await validate({ component });
  } catch (e) {
    if (e?.redirected) {
      return;
    }
  }

  if (!validateResponse?.component) {
    return goNextRoute(from, {
      name: 'Forbidden',
    });
  }

  const {
    token,
    dict_versions = {},
  } = validateResponse;

  if (Object.keys(dict_versions).length > 0) {
    await idbDict.set('dict_versions', dict_versions);
  }

  if (!token) {
    return goNextRoute(from, {
      name: 'Logout',
      query: { destination: nextPath },
    });
  }

  return goNextRoute(from, null);
}
