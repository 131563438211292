import authorize from "@/router/middleware/authorize";
import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer } from "@/router/components";

const UserProfile = () => import(/* webpackChunkName: "user-profile" */ '@/views/profile/UserProfile');
// const PrivacyCookiesPolicy = () => import(/* webpackChunkName: "cookies-policy" */ '@/views/pages/PrivacyCookiesPolicy');
const Logout = () => import(/* webpackChunkName: "logout" */ '@/views/pages/Logout');
const ReissueToken = () => import(/* webpackChunkName: "reissue-token" */ '@/views/pages/ReissueToken');

const PageError = () => import(/* webpackChunkName: "page-error" */ '@/views/pages/PageError');
const Page404 = () => import(/* webpackChunkName: "page-error" */ '@/views/pages/Page404');
const Page500 = () => import(/* webpackChunkName: "page-error" */ '@/views/pages/Page500');
const PageNetworkError = () => import(/* webpackChunkName: "page-error" */ '@/views/pages/PageNetworkError');
const PageForbidden = () => import(/* webpackChunkName: "page-error" */ '@/views/pages/PageForbidden');
const SubscriptionExpired = () => import(/* webpackChunkName: "page-error" */ '@/views/pages/SubscriptionExpired');
const ClientAccessDenied = () => import(/* webpackChunkName: "page-error" */ '@/views/pages/ClientAccessDenied');

const MagicLink = () => import(/* webpackChunkName: "magic-link" */ '@/views/pages/MagicLink');
const LoginUser = () => import(/* webpackChunkName: "login-user" */ '@/views/pages/LoginUser');
const Redirect = () => import(/* webpackChunkName: "redirect" */ '@/views/pages/Redirect');
// const Maintenance = () => import(/* webpackChunkName: "guest" */ '@/views/pages/Maintenance');
const FileDownload = () => import(/* webpackChunkName: "file-download" */ '@/views/pages/FileDownload');
const CompanyAnalysis = () => import(/* webpackChunkName: "company-analysis" */ '@/views/pages/CompanyAnalysis');
const CompanyAnalysisRedirect = () => import(/* webpackChunkName: "company-analysis-redirect" */ '@/views/pages/CompanyAnalysisRedirect');
const MaintenanceMode = () => import(/* webpackChunkName: "maintenance-mode" */ '@/views/pages/MaintenanceMode.vue');
const CorporateAuth = () => import(/* webpackChunkName: "corporate-auth" */ '@/views/pages/CorporateAuth.vue');

export default [
  {
    meta: {
      dictKey: 'DashboardContainer',
    },
    path: '/profile',
    component: DashboardContainer,
    children: [
      {
        meta: {
          breadcrumb: { name: 'profile' },
          component: 'user_profile',
          middleware: [validateComponent],
          dictKey: 'UserProfile',
        },
        path: '/profile',
        name: 'UserProfile',
        component: UserProfile,
      },
    ],
  },
  // {
  //   meta: {
  //     dictKey: 'DashboardContainer',
  //   },
  //   path: '/privacy-cookies-policy',
  //   component: DashboardContainer,
  //   children: [
  //     {
  //       meta: {
  //         breadcrumb: { name: 'Policy' },
  //         component: 'privacy_policy',
  //         middleware: [authorize],
  //         dictKey: 'PrivacyCookiesPolicy',
  //       },
  //       path: '/privacy-cookies-policy',
  //       name: 'Policy',
  //       component: PrivacyCookiesPolicy,
  //     },
  //   ],
  // },
  {
    path: '/reissue-token',
    name: 'ReissueToken',
    component: ReissueToken,
  },
  {
    meta: {
      dictKey: 'FileDownload',
    },
    path: '/file-download/:id',
    name: 'FileDownload',
    component: FileDownload,
  },
  {
    meta: {
      dictKey: 'PageError',
      errorPage: true,
    },
    path: '/error',
    name: 'PageError',
    component: PageError,
  },
  {
    meta: {
      dictKey: 'Page500',
      errorPage: true,
    },
    path: '/500',
    name: 'Page500',
    component: Page500,
  },
  {
    meta: {
      dictKey: 'PageNetworkError',
      errorPage: true,
    },
    path: '/network-error',
    name: 'PageNetworkError',
    component: PageNetworkError,
  },
  {
    meta: {
      dictKey: 'Forbidden',
      errorPage: true,
    },
    path: '/forbidden',
    name: 'Forbidden',
    component: PageForbidden,
  },
  {
    meta: {
      errorPage: true,
    },
    path: '/expired',
    name: 'SubscriptionExpired',
    component: SubscriptionExpired,
  },
  {
    meta: {
      systemPage: true,
      auth: true,
      forbiddenForUserSubdomain: true,
      disableAutoDestination: true,
    },
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    meta: {
      systemPage: true,
    },
    path: '/redirect',
    name: 'Redirect',
    component: Redirect,
  },
  {
    meta: {
      auth: true,
      systemPage: true,
      forbiddenForUserSubdomain: true,
    },
    path: '/magic_link',
    name: 'MagicLink',
    component: MagicLink,
  },
  {
    meta: {
      systemPage: true,
    },
    path: '/login-user',
    name: 'LoginUser',
    component: LoginUser,
  },
  {
    meta: {
      dictKey: 'CompanyAnalysis',
      // middleware: [authorize],
      systemPage: true,
    },
    path: '/ca-widget',
    name: 'CompanyAnalysis',
    component: CompanyAnalysis,
  },
  {
    meta: {
      dictKey: 'CompanyAnalysis',
      middleware: [authorize],
      systemPage: true,
    },
    path: '/ca-redirect',
    name: 'CompanyAnalysisRedirect',
    component: CompanyAnalysisRedirect,
  },
  {
    meta: {
      dictKey: 'Page404',
      errorPage: true,
    },
    path: '/404',
    component: Page404,
    name: 'Page404',
  },
  {
    meta: {
      systemPage: true,
    },
    path: '/maintenance-mode',
    component: MaintenanceMode,
    name: 'MaintenanceMode',
  },
  {
    meta: {
      systemPage: true,
    },
    path: '/corp-sso',
    component: CorporateAuth,
    name: 'CorporateAuth',
  },
  {
    meta: {
      dictKey: 'ClientAccessDenied',
      errorPage: true,
    },
    path: '/client-access-denied',
    name: 'ClientAccessDenied',
    component: ClientAccessDenied,
  },
  {
    meta: {
      dictKey: 'Page404',
    },
    path: '*',
    component: Page404,
  },
];
