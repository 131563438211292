<template>
  <loading-app-layout />
</template>

<script>
import LoadingAppLayout from "@/containers/app-layouts/LoadingAppLayout.vue";
import { getHomeRoute } from '@/config/router';

export default {
  name: 'home',
  components: {
    LoadingAppLayout,
  },
  data() {
    return {};
  },
  mounted() {
    const isUserAuth = this.$store.getters['Account/isAuthorized'];

    if (!isUserAuth) {
      const highLevelDomain = window.location.hostname.split('.').slice(-1).join('.');
      const isPWA = window.matchMedia('(display-mode: standalone)').matches;

      if (window.envMode === enums.ENV_MODES.PRODUCTION && highLevelDomain === 'fi' && !isPWA) {
        window.location.href = 'info/';
        return;
      }
    }

    const homeRoute = getHomeRoute();

    if (homeRoute.name !== 'Home') {
      this.$router.push(homeRoute).catch(() => {});
    } else {
      this.$router.push({ name: 'Logout' });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
