import {
  getModules
} from "@/api/repositories/systemRepository";
import { IndexedArray } from "@/shared/proxies";
import { USER_ADDONS } from "@/config/enums";
import { entityModulesIds, modulesPriority } from "@/config/entity-modules";
import Vue from "vue";

const initialState = () => ({
  modules: undefined,
});

function getEnumModules() {
  const enumModules = Object.entries(USER_ADDONS)
    .map(([key, id]) => ({
      id,
      key,
      name: Vue.prototype.$vDict(`enums.user_addons_${key.toLowerCase()}.text`),
    }));

  return new IndexedArray(enumModules);
}

function getResultModule(module) {
  const res = {
    ...module,
    main: [],
    fixed: [],
  };

  Object.entries(entityModulesIds).forEach(([key, values]) => {
    const entityTypeId = Number(key);
    const {
      main = [],
      fixed = [],
    } = values;

    if (main.includes(module.id)) {
      res.main.push(entityTypeId);
    }

    if (fixed.includes(module.id)) {
      res.fixed.push(entityTypeId);
    }
  });

  return res;
}

function getResultModules(modules = []) {
  const enumModules = getEnumModules();
  const res = modules.map(moduleData => {
    const module = getResultModule(moduleData);
    const enumModule = enumModules.findById(module.id);

    return {
      ...module,
      name: enumModule ? enumModule.name : module.name,
    };
  });

  // sort modules by priority
  res.sort((a, b) => modulesPriority.indexOf(a.id) - modulesPriority.indexOf(b.id));

  return new IndexedArray(res);
}

export default {
  state: initialState,
  getters: {
    modules(state) {
      return state.modules || new IndexedArray([]);
    },
    getModules: state => payload => {
      const { entityTypeId, sectionKey } = payload;
      const modules = state.modules.filter(module => {
        const entityTypes = module[sectionKey] || [];
        return entityTypes.includes(entityTypeId);
      });

      return new IndexedArray(modules);
    },
  },
  mutations: {
    SET_MODULES(state, modules = []) {
      state.modules = modules;
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    async fetchModules({ state, commit }) {
      if (state.modules) {
        return state.modules;
      }

      let modules = [];

      try {
        const response = await getModules({
          offset: 0,
          limit: process.env.VUE_APP_MAX_LIMIT,
        });
        modules = getResultModules(response?.data || []);

        commit('SET_MODULES', modules);
      } catch (e) {}

      return modules;
    },
  },
  namespaced: true,
};
