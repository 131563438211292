import Vue from 'vue';
import store from '@/store';
import { getDictVersions, idmp } from '@/api/repositories/miscRepository';

export function isAuthorized() {
  return !!store.getters['Account/isAuthorized'];
}

export function checkUserLanguage(route) {
  if (window.logged_as_user) {
    return;
  }

  const langId = Vue.prototype.$getDictLanguage('id');
  const userLangId = Number(
    store.getters['Account/getSettingsValue']('user.language_id') ||
    route.query.set_language_id ||
    langId
  );

  return userLangId !== langId ? userLangId : null;
}

export function initUserLikeChat() {
  if (window.highLevelDomain !== 'no') {
    return;
  }

  let payload = {
    widgetType: 'guest',
    userData: {},
  };

  if (isAuthorized()) {
    Object.assign(payload, {
      widgetType: 'authorized',
      userData: {
        contactInfo: {
          name: store.getters['Account/getSettingsValue']('user.name'),
          email: store.getters['Account/getSettingsValue']('user.email'),
        },
        customData: {
          company: store.getters['Account/getSettingsValue']('client.name'),
          external_customer_id: store.getters['Account/getSettingsValue']('client.source_id'),
        },
      },
    });
  }

  return Vue.prototype.$initUserLikeMessenger(payload.widgetType, payload.userData);
}

export function isShowRelease(to) {
  return !!(to?.query?.showReleases);
}

export async function loadNewDictVersion(idbDict) {
  try {
    const response = await getDictVersions();

    if (response && Object.keys(response).length > 0) {
      await idbDict.set('dict_versions', response);
    }
  } catch (e) { }
}

export async function checkMiddleware(to, from) {
  const { middleware } = to.meta;
  let needLoadDictVersions = !to.meta.systemPage;
  let routeData = null;

  if (from.path !== to.path) {
    store.commit('DLH_RESET');
    store.commit('DLH_LOAD', {
      type: 'middleware',
    });
  }

  if (middleware && middleware.length > 0) {
    const context = {
      to,
      from,
      store,
    };
    const resMiddleware = await middleware[0](context);

    if (needLoadDictVersions) {
      needLoadDictVersions = !middleware.some(el => el.name === 'validateComponent');
    }

    routeData = typeof resMiddleware === 'object' && !_.isEmpty(resMiddleware) ? resMiddleware : null;
  }

  return { needLoadDictVersions, routeData };
}

export async function checkActualToken() {
  const isAuth = store.getters['Account/isAuthorized'];

  if (!isAuth) {
    return;
  }

  try {
    await idmp(false);
  } catch (e) {
    await store.dispatch('Account/logout');
  }
}
