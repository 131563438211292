import _ from 'lodash';
import vars from '@/config/vars';
import store from '@/store';
import enums from '@/config/enums';
import Vue from 'vue';

/* Round to the required number of decimal places and fix Math.round bug */
export function fixRound(value, decimals) {
  const tVal = Math.round(Number(`${value}e${decimals}`));
  return Number(`${tVal}e-${decimals}`);
}

/* Get a string from a number with the required fixed number of decimal places */
export function toFixed(value, decimals) {
  return fixRound(value, decimals).toFixed(decimals);
}

export function formatSqearMeter(val) {
  return val ? `${formatNumber(val)} m²` : '';
}

export function formatCubicMeter(val) {
  return val ? `${formatNumber(val)} m³` : '';
}

export function getExponent(value) {
  let exp = 0;
  if (Math.abs(Number(value)) >= 1.0e9) {
    exp = 9;
  } else if (Math.abs(Number(value)) >= 1.0e6) {
    exp = 6;
  } else if (Math.abs(Number(value)) >= 1.0e3) {
    exp = 3;
  }
  return exp;
}

export function getCurrencySymbol(currencyId, number = 0) {
  const lang = Vue.prototype.$getDictLanguage('intlIso');
  const lCurrency = Vue.prototype.$lFind('global.currencies', {
    id: currencyId || store.getters['Account/getSettingsValue']('user.currency_id'),
  });

  if (!lCurrency) {
    return {};
  }

  const formatParts = Intl.NumberFormat(lang, {
    style: 'currency',
    currency: lCurrency?.code,
    notation: "compact",
  }).formatToParts(number);
  const currencyIndex = formatParts.findIndex(el => el.type === 'currency');
  const formatObject = formatParts.reduce((obj, part) => ({
    ...obj,
    [part.type]: part.value,
  }), {});
  const { currency, compact } = formatObject;
  let symbolWithCompactArr;
  let symbolSide;

  if (currencyIndex > 0) {
    symbolSide = 'right';
    symbolWithCompactArr = [compact, currency];
  } else {
    symbolSide = 'left';
    symbolWithCompactArr = [currency, compact];
  }

  return {
    symbol: currency,
    symbolSide,
    compact,
    symbolWithCompact: _.compact(symbolWithCompactArr).join(' '),
  };
}

/* Get a string from a number in short form (e.g. 10K, 1,000.5M) */
export function shortFormatNumber(number) {
  const lang = Vue.prototype.$getDictLanguage('intlIso');
  const roundNumber = Math.ceil(number);
  return new Intl.NumberFormat(lang, {
    notation: "compact",
    compactDisplay: "short"
  }).format(roundNumber);
}

export function formatNumberToParts(num, options = {}) {
  const opts = {
    decimal: false,
    round: false,
    formatOptions: {},
    ...options,
  };
  const lang = Vue.prototype.$getDictLanguage('intlIso');
  const exponent = getExponent(num);
  const numFormatOptions = {};
  let number = num;

  if (opts.round) {
    number = Number(number.toPrecision(2));
  }

  if (exponent >= 6) {
    Object.assign(numFormatOptions, {
      notation: "compact",
    });
  } else if (opts.decimal) {
    // number = Math.round((number + Number.EPSILON) * 100) / 100;

    Object.assign(numFormatOptions, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }

  Object.assign(numFormatOptions, opts.formatOptions);

  return new Intl.NumberFormat(lang, numFormatOptions).formatToParts(number);
}

export function formatCostToParts(num, options = {}) {
  const opts = {
    round: false,
    decimal: true,
    currencyId: store.getters['Account/getSettingsValue']('user.currency_id'),
    ...options,
  };
  const currency = Vue.prototype.$lFind('global.currencies', {
    id: opts.currencyId,
  });

  return formatNumberToParts(num, {
    decimal: opts.decimal,
    round: opts.round,
    formatOptions: {
      style: 'currency',
      currency: currency?.code,
      minimumFractionDigits: 0,
    },
  });
}

function isValidNumberForFormatted(val) {
  return (typeof val === 'string' && !val) ||
    typeof val === 'number';
}

export function formatNumber(num, options = {}) {
  if (!isValidNumberForFormatted(num)) {
    return '';
  }

  const parts = formatNumberToParts(num, options);
  return parts.reduce((string, part) => string + part.value, '');
}

function formatCost(num, options = {}) {
  const parts = formatCostToParts(num, options);
  return parts.reduce((string, part) => string + part.value, '');
}

export function formatRangeNumber(value, options) {
  const vals = Array.isArray(value) ? [_.head(value), _.last(value)] : [value];
  const range = vals
    .reduce((acum, val) => (val !== null && !acum.includes(val) ? [...acum, val] : acum), [])
    .map(val => formatNumber(val, options));

  return range.join(' - ');
}

export function formatRangeCost(value, options) {
  const defOptions = {
    decimal: true,
    round: false,
    currencyId: store.getters['Account/getSettingsValue']('user.currency_id'),
  };
  const opts = _.defaults({}, options, defOptions);
  const currencySymbol = getCurrencySymbol(opts.currencyId);
  const rangeNumber = formatRangeNumber(value, opts);
  const { symbolSide, symbol } = currencySymbol;

  return symbolSide === 'left' ? `${symbol} ${rangeNumber}` : `${rangeNumber} ${symbol}`;
}

export function formatToLowercase(value) {
  if (!value) return '';
  const str = value.toString();
  return str.toLowerCase();
}

export function formatToCapitalize(value) {
  if (!value) return '';
  const str = value.toString();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * @return {string}
 */
export function formatOrgNumber(org_nr, country) {
  let data = [];
  let res;
  switch (country) {
    case enums.COUNTRIES.SWEDEN:
      data = _.compact([org_nr.substring(0, org_nr.length - 4), org_nr.substring(org_nr.length - 4, org_nr.length)]);
      res = data.join('-');
      break;
    case enums.COUNTRIES.DENMARK:
      res = org_nr;
      break;
    case enums.COUNTRIES.FINLAND:
      data = _.compact([org_nr.substring(0, org_nr.length - 1), org_nr.substring(org_nr.length - 1, org_nr.length)]);
      res = data.join('-');
      break;
    case enums.COUNTRIES.NORWAY:
      res = org_nr ? org_nr.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : '';
      break;
    default:
      res = org_nr;
  }
  return res;
}

export function formatBudgetValue(val, min, max, currencyId) {
  const maxValue = max || vars.CONST_MAX_BUDGET_LIMIT;
  const minValue = min || 0;
  const formatConfig = {};
  let value;
  let res = '';

  if (typeof val !== 'number') {
    value = 0;
  } else {
    value = val < minValue ? minValue :
      (val > maxValue ? maxValue : val);
  }

  if (currencyId) {
    res = formatCost(value, { ...formatConfig, currencyId });
  } else {
    res = formatNumber(value, formatConfig);
  }

  if (value === vars.CONST_MAX_BUDGET_LIMIT) {
    res += '+';
  }

  return res;
}

function getBudgetRange(values, options = {}) {
  const opts = {
    min: null,
    max: null,
    currencyId: store.getters['Account/getSettingsValue']('user.currency_id'),
    ...options,
  };
  const budgetRange = values || [];

  return {
    from: formatBudgetValue(budgetRange[0] ?? opts.min, opts.min, opts.max),
    to: formatBudgetValue(budgetRange[1] ?? opts.max, opts.min, opts.max),
    currencySymbol: getCurrencySymbol(opts.currencyId),
  };
}

export function formatBudgetRange(values, options = {}) {
  const budgetRange = getBudgetRange(values, options);
  const range = `${budgetRange.from} - ${budgetRange.to}`;
  const { currencySymbol } = budgetRange;

  return currencySymbol.symbolSide === 'left' ?
    `${currencySymbol.symbol} ${range}` : `${range} ${currencySymbol.symbol}`;
}

export function formatBudgetRangeFromTo(values, options = {}) {
  const budgetRange = getBudgetRange(values, options);
  const text_from = Vue.prototype.$vDict('global.text_from.text');
  const text_to = Vue.prototype.$vDict('global.text_to.text');
  const { currencySymbol } = budgetRange;
  const rangeText = `${budgetRange.from} ${text_to} ${budgetRange.to}`;
  const rangeTextWithSymbol = currencySymbol.symbolSide === 'left' ?
    `${currencySymbol.symbol} ${rangeText}` : `${rangeText} ${currencySymbol.symbol}`;

  return `${text_from} ${rangeTextWithSymbol}`;
}

export function formatBytes(bytes, decimals = 2) {
  const lang = Vue.prototype.$getDictLanguage('intlIso');
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['byte', 'kilobit', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];
  let i = 0;
  let number = 0;

  if (bytes > 0) {
    i = Math.floor(Math.log(bytes) / Math.log(k));
    number = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }

  return Intl.NumberFormat(lang, {
    style: 'unit',
    unit: sizes[i],
  }).format(number);
}
