import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import Home from '@/views/pages/Home';
import IndexedDBStorage from '@/core/storage/drivers/indexedDBStorage';
import { hjSetAttributes } from '@/utils/hotjar';

import leadsRoutes from '@/router/modules/leads';
import salesRoutes from '@/router/modules/sales';
import myranLogsRoutes from '@/router/modules/myran-logs';
import onboardingRoutes from "@/router/modules/onboarding";
import apiSettingsRoutes from "@/router/modules/api-settings";
import supportRoutes from "@/router/modules/support";
import systemRoutes from "@/router/modules/system";
import usageStatsRoutes from "@/router/modules/usage-stats";
import pagesRoutes from "@/router/modules/pages";
import vortalConnectRoutes from "@/router/modules/vortal-connect";
import logsRoutes from "@/router/modules/logs";
import parabygMigrationRoutes from "@/router/modules/parabyg-migration";

import {
  checkUserLanguage,
  initUserLikeChat,
  isShowRelease,
  loadNewDictVersion,
  checkMiddleware,
  checkActualToken,
} from './utils';

import {
  checkMaintenanceMode,
  checkNetworkError,
  checkSetClientId,
  checkRequiredQuery,
  checkInvalidParams,
  checkAccessToUserSubdomain,
} from "@/router/guards";

const routes = _.concat(
  leadsRoutes,
  salesRoutes,
  myranLogsRoutes,
  onboardingRoutes,
  apiSettingsRoutes,
  supportRoutes,
  logsRoutes,
  systemRoutes,
  usageStatsRoutes,
  vortalConnectRoutes,
  pagesRoutes,
  parabygMigrationRoutes,
);

const idbDict = IndexedDBStorage('DictionaryStorage');

Vue.use(Router);

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior(to, from) {
    if (from.path === to.path) {
      return {};
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      meta: {
        systemPage: true,
      },
      path: '/',
      name: 'Home',
      component: Home,
    },
    ...routes,
  ],
});

router.beforeEach(async (to, from, next) => {
  const guards = [
    checkMaintenanceMode,
    checkSetClientId,
    checkRequiredQuery,
    checkNetworkError,
    checkInvalidParams,
    checkAccessToUserSubdomain,
  ];

  const isIntercepted = guards.some(guardFunc => guardFunc(next, to));

  if (isIntercepted) {
    return;
  }

  if (to.meta?.auth && !to.meta?.systemPage) {
    await checkActualToken();
  }

  if (isShowRelease(to)) {
    localStorage.setItem('force_show_release_notes', 'true');
  }

  const { needLoadDictVersions, routeData } = await checkMiddleware(to, from);

  if (needLoadDictVersions) {
    await loadNewDictVersion(idbDict);
  }

  if (routeData) {
    next(routeData);
    return;
  }

  next();
});

router.afterEach(async (to, from, next) => {
  const newLangId = checkUserLanguage(to);

  if (newLangId) {
    Vue.prototype.$changeDictLanguage({
      langId: newLangId,
      route: to,
    });
  } else {
    Vue.prototype.$initDictSectionsByRoute({
      route: to,
    });
  }

  if (from.path !== to.path) {
    store.commit('DLH_SUCCESS', {
      type: 'middleware',
    });
  }

  // init user like chat
  if (to.meta.systemPage) {
    Vue.prototype.$hideUserLikeMessenger();
  } else {
    initUserLikeChat();
  }

  hjSetAttributes();
  store.dispatch('NotifQueue/startTiming');
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
