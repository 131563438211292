import validateComponent from "@/router/middleware/validateComponent";
import { DashboardContainer } from "@/router/components";

const SystemComponents = () => import(/* webpackChunkName: "system-components" */ '@/views/system/components/ListComponents');
const SystemEditorComponent = () => import(/* webpackChunkName: "system-component-editor" */ '@/views/system/components/EditorComponent');

// System / Modules
const SystemModules = () => import(/* webpackChunkName: "system-modules" */ '@/views/system/modules/ListModules');
const SystemEditorModule = () => import(/* webpackChunkName: "system-module-editor" */ '@/views/system/modules/EditorModule');

// System / Default fieldsets
const SystemDefaultFieldsets = () => import(/* webpackChunkName: "system-default-fieldsets" */ '@/views/system/default-fieldsets/ListDefaultFieldsets');
const SystemEditorDefaultFieldset = () => import(/* webpackChunkName: "system-default-fieldset-editor" */ '@/views/system/default-fieldsets/EditorDefaultFieldset');

// System / Dead Messages
const DeadMessagesList = () => import(/* webpackChunkName: "dead-messages" */ '@/views/system/dead-messages/DeadMessagesList');
const DeadMessageEditor = () => import(/* webpackChunkName: "dead-message-editor" */ '@/views/system/dead-messages/EditorDeadMessage');

// System / Dead Message subscriptions
const DeadMessageSubscriptionsList = () => import(/* webpackChunkName: "dead-message-subscriptions" */ '@/views/system/dead-message-subscriptions/DeadMessageSubscriptionsList');
const DeadMessageSubscriptionEditor = () => import(/* webpackChunkName: "dead-message-subscription-editor" */ '@/views/system/dead-message-subscriptions/EditorDeadMessageSubscription');

const CustomFieldsetTemplates = () => import (/* webpackChunkName: "custom-fieldset-templates" */ '@/views/system/custom-fieldset-templates/CustomFieldsetTemplates');
const CustomFieldsetTemplateEditor = () => import(/* webpackChunkName: "custom-fieldset-template-editor" */ '@/views/system/custom-fieldset-templates/EditorCustomFieldsetTemplate');

// System / Custom field metrics
const CustomFieldMetricsList = () => import(/* webpackChunkName: "custom-field-metrics" */ '@/views/system/custom-field-metrics/CustomFieldMetricsList');
const CustomFieldMetricEditor = () => import(/* webpackChunkName: "custom-field-metric-editor" */ '@/views/system/custom-field-metrics/CustomFieldMetricEditor');

// System / Custom field selection metrics
const CustomFieldSelectionMetricsList = () => import(/* webpackChunkName: "custom-field-selection-metrics" */ '@/views/system/custom-field-selection-metrics/CustomFieldSelectionMetricsList');
const CustomFieldSelectionMetricEditor = () => import(/* webpackChunkName: "custom-field-selection-metric-editor" */ '@/views/system/custom-field-selection-metrics/CustomFieldSelectionMetricEditor');

// System / Dead Messages
const ErrorMessagesList = () => import(/* webpackChunkName: "error-messages" */ '@/views/system/error-messages/ErrorMessagesList');
// const ErrorMessageEditor = () => import(/* webpackChunkName: "error-message-editor" */ '@/views/system/dead-messages/EditorDeadMessage');


// System / Licenses
const SystemLicenses = () => import(/* webpackChunkName: "licenses" */ '@/views/system/licenses/LicensesList');
const SystemLicenseEditor = () => import(/* webpackChunkName: "licenses" */ '@/views/system/licenses/LicenseEditor');

export default [
  {
    meta: {
      breadcrumb: { name: 'system' },
      dictKey: 'DashboardContainer',
      menuId: 36,
    },
    path: '/system',
    redirect: '/system/components',
    name: 'System',
    component: DashboardContainer,
    children: [
      {
        path: 'components',
        redirect: '/system/components',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'system_components',
              middleware: [validateComponent],
              breadcrumb: { name: 'Components' },
              menuId: 38,
              dictKey: 'SystemComponents',
            },
            path: '/system/components',
            name: 'SystemComponents',
            component: SystemComponents,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit component', dynamic: true },
              component: 'system_components',
              middleware: [validateComponent],
              parentMenuId: 38,
            },
            path: ':id/edit',
            name: 'SystemEditorComponent',
            component: SystemEditorComponent,
          },
          {
            meta: {
              breadcrumb: { name: 'Create component' },
              component: 'system_components',
              middleware: [validateComponent],
              parentMenuId: 38,
            },
            path: 'new',
            name: 'SystemEditorComponentNew',
            component: SystemEditorComponent,
          },
        ],
      },
      {
        path: 'modules',
        redirect: '/system/modules',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'system_modules',
              middleware: [validateComponent],
              breadcrumb: {
                name: 'Modules',
              },
              menuId: 37,
              dictKey: 'SystemModules',
            },
            path: '/system/modules',
            name: 'SystemModules',
            component: SystemModules,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit module', dynamic: true },
              component: 'system_modules',
              middleware: [validateComponent],
              parentMenuId: 37,
              dictKey: 'SystemModules',
            },
            path: ':id/edit',
            name: 'SystemEditorModule',
            component: SystemEditorModule,
          },
          {
            meta: {
              breadcrumb: { name: 'Create module' },
              component: 'system_modules',
              middleware: [validateComponent],
              parentMenuId: 37,
              dictKey: 'SystemModules',
            },
            path: 'new',
            name: 'SystemEditorModuleNew',
            component: SystemEditorModule,
          },
        ],
      },
      {
        path: 'default-fieldsets',
        redirect: '/system/default-fieldsets',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              component: 'system_default_fieldsets',
              middleware: [validateComponent],
              breadcrumb: { name: 'Default fieldsets' },
              menuId: 54,
              dictKey: 'SystemDefaultFieldsets',
            },
            path: '/system/default-fieldsets',
            name: 'SystemDefaultFieldsets',
            component: SystemDefaultFieldsets,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit default fieldset', dynamic: true },
              component: 'system_default_fieldsets',
              middleware: [validateComponent],
              parentMenuId: 54,
              dictKey: 'SystemDefaultFieldsets',
            },
            path: ':id/edit',
            name: 'SystemEditorDefaultFieldset',
            component: SystemEditorDefaultFieldset,
          },
          {
            meta: {
              breadcrumb: { name: 'Create default fieldset' },
              component: 'system_default_fieldsets',
              middleware: [validateComponent],
              parentMenuId: 54,
              dictKey: 'SystemDefaultFieldsets',
            },
            path: 'new',
            name: 'SystemEditorDefaultFieldsetNew',
            component: SystemEditorDefaultFieldset,
          },
        ],
      },
      {
        path: 'dead-messages',
        redirect: '/system/dead-messages',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              breadcrumb: { name: 'Dead messages' },
              component: 'dead_messages_list',
              middleware: [validateComponent],
              menuId: 73,
              dictKey: 'SystemDeadMessages',
            },
            path: '/system/dead-messages',
            name: 'SystemDeadMessages',
            component: DeadMessagesList,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit dead message', dynamic: true },
              component: 'dead_messages_list',
              middleware: [validateComponent],
              parentMenuId: 73,
              dictKey: 'SystemDeadMessageEditor',
            },
            path: ':id/edit',
            name: 'SystemDeadMessageEditor',
            component: DeadMessageEditor,
          }
        ],
      },
      {
        path: 'dead-message-subscriptions',
        redirect: '/system/dead-message-subscriptions',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              breadcrumb: { name: 'Dead message subscriptions' },
              component: 'dead_message_subscriptions',
              middleware: [validateComponent],
              menuId: 74,
              dictKey: 'SystemDeadMessageSubscriptions',
            },
            path: '/system/dead-message-subscriptions',
            name: 'SystemDeadMessageSubscriptions',
            component: DeadMessageSubscriptionsList,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit dead message subscription', dynamic: true },
              component: 'dead_message_subscriptions',
              middleware: [validateComponent],
              parentMenuId: 74,
              dictKey: 'SystemDeadMessageSubscriptionEditor',
            },
            path: ':id/edit',
            name: 'SystemDeadMessageSubscriptionEditor',
            component: DeadMessageSubscriptionEditor,
          }
        ],
      },
      {
        path: 'custom-fieldset-templates',
        redirect: '/system/custom-fieldset-templates',
        component: {
          render(c) {
            return c('router-view');
          }
        },
        children: [
          {
            meta: {
              breadcrumb: { name: 'Custom fieldset templates' },
              component: 'custom_field_templates_list',
              middleware: [validateComponent],
              menuId: 75,
              dictKey: 'SystemCustomFieldsetTemplates',
            },
            path: '/system/custom-fieldset-templates',
            name: 'SystemCustomFieldsetTemplates',
            component: CustomFieldsetTemplates,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit custom field templates', dynamic: true },
              component: 'custom_field_templates_list',
              middleware: [validateComponent],
              parentMenuId: 75,
              dictKey: 'SystemCustomFieldsetTemplates',
            },
            path: ':id/edit',
            name: 'SystemCustomFieldsetTemplateEditor',
            component: CustomFieldsetTemplateEditor,
          }
        ]
      },
      {
        path: 'custom-field-metrics',
        redirect: '/system/custom-field-metrics',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              breadcrumb: { name: 'Custom field metrics' },
              component: 'custom_field_metrics_list',
              middleware: [validateComponent],
              menuId: 76,
              dictKey: 'SystemCustomFieldMetrics',
            },
            path: '/system/custom-field-metrics',
            name: 'SystemCustomFieldMetrics',
            component: CustomFieldMetricsList,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit custom field metric', dynamic: true },
              component: 'custom_field_metrics_list',
              middleware: [validateComponent],
              parentMenuId: 76,
              dictKey: 'SystemCustomFieldMetricEditor',
            },
            path: ':id/edit',
            name: 'SystemCustomFieldMetricEditor',
            component: CustomFieldMetricEditor,
          },
          {
            meta: {
              breadcrumb: { name: 'Create custom field metric' },
              component: 'custom_field_metrics_list',
              middleware: [validateComponent],
              parentMenuId: 76,
              dictKey: 'SystemCustomFieldMetricEditor',
            },
            path: 'new',
            name: 'SystemCustomFieldMetricNew',
            component: CustomFieldMetricEditor,
          },
        ],
      },
      {
        path: 'custom-field-selection-metrics',
        redirect: '/system/custom-field-selection-metrics',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              breadcrumb: { name: 'Custom field selection metrics' },
              component: 'custom_field_selection_metrics_list',
              middleware: [validateComponent],
              menuId: 76,
              dictKey: 'SystemCustomFieldSelectionMetrics',
            },
            path: '/system/custom-field-selection-metrics',
            name: 'SystemCustomFieldSelectionMetrics',
            component: CustomFieldSelectionMetricsList,
          },
          {
            meta: {
              breadcrumb: { name: 'Edit custom field selection metric', dynamic: true },
              component: 'custom_field_selection_metrics_list',
              middleware: [validateComponent],
              parentMenuId: 76,
              dictKey: 'SystemCustomFieldSelectionMetricEditor',
            },
            path: ':id/edit',
            name: 'SystemCustomFieldSelectionMetricEditor',
            component: CustomFieldSelectionMetricEditor,
          },
          {
            meta: {
              breadcrumb: { name: 'Create custom field selection metric' },
              component: 'custom_field_selection_metrics_list',
              middleware: [validateComponent],
              parentMenuId: 76,
              dictKey: 'SystemCustomFieldSelectionMetricEditor',
            },
            path: 'new',
            name: 'SystemCustomFieldSelectionMetricNew',
            component: CustomFieldSelectionMetricEditor,
          },
        ],
      },
      {
        path: 'error-messages',
        redirect: '/system/error-messages',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              breadcrumb: { name: 'Error messages' },
              component: 'error_messages_list',
              middleware: [validateComponent],
              menuId: 91,
              dictKey: 'SystemErrorMessages',
            },
            path: '/system/error-messages',
            name: 'SystemErrorMessages',
            component: ErrorMessagesList,
          },
        ],
      },
      {
        path: 'licenses',
        redirect: '/system/licenses',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            meta: {
              breadcrumb: { name: 'Licenses' },
              component: 'licenses_list',
              // middleware: [validateComponent],
              menuId: 93,
              dictKey: 'SystemLicenses',
            },
            path: '/system/licenses',
            name: 'SystemLicenses',
            component: SystemLicenses,
          },
          
          {
            meta: {
              breadcrumb: { name: 'Edit License', dynamic: true },
              component: 'license_edit',
              // middleware: [validateComponent],
              parentMenuId: 93,
              dictKey: 'SystemLicenseEditor',
            },
            path: ':id/edit',
            name: 'SystemLicenseEditor',
            component: SystemLicenseEditor,
          },
        ],
      },
    ],
  },
];
